@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap"); /* Noto Sans font 가져옴 */

.id-container {
  width: 328px;
  position: relative;
  z-index: 100;
}

.id-box {
  width: 328px;
  height: 260px;
  left: -1px;
  top: -1px;
  border-radius: 12px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
}

.id-input-container {
  width: 280px;
  height: 48px;
  left: 24px;
  background: #fafafa;
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.id-input_text {
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-size: 16px;
  font-weight: 500;
  color: black;
  caret-color: #e8a44b;
  margin-left: 16px;
  border: none;
  background: 0 0;
}

.id-input-container :focus {
  outline: 1px solid #e8a44b;
}

.id-button-container {
  width: 280px;
  height: 48px;
  left: 24px;
  top: 172px;
}

.id-login-button {
  width: 280px;
  height: 48px;
  background: #213e57;
  border-radius: 4px;
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  color: #fff;
  cursor: pointer;
}

.id-clear-button {
  background: none;
  border: none;
  cursor: pointer;
}
