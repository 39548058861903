@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap"); /* Noto Sans font 가져옴 */

* {
  box-sizing: border-box; /* 모든 요소에 적용 */
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
}

.select-container {
  display: flex;
  gap: 12px; /* Select 요소 간의 간격 */
  margin-bottom: 16px;
}

select {
  width: 96px; /* Select 너비 */
  height: 32px; /* Select 높이 */
  padding-left: 8px; /* 내부 좌측 패딩 */
  cursor: pointer;
  background: #14314a url("../icons/down.png") no-repeat right 8px center; /* 배경색 및 화살표 이미지 위치 설정 */
  appearance: none; /* 기본 스타일 제거 */
  border: 1px solid #ccc; /* 테두리 설정 */
  color: #fff; /* 글자 색상 */
}

.chart-container {
  width: 896px;
  max-width: 100%; /* 차트 컨테이너의 최대 너비를 화면 너비에 맞춤 */
  height: 336px;
  background-color: #f8f8f8;
  box-shadow: 0px 24px 48px -8px rgba(39, 76, 119, 0.16);
  padding: 8px;
  overflow-x: auto;
  overflow-y: hidden; /* 세로 스크롤은 숨김 */
  border-radius: 8px; /* 경계선 반경 설정 */
}

.chart-wrapper {
  min-width: 896px; /* 차트 전체를 보기 위해 충분히 큰 최소 너비 설정 */
  height: 100%;
}

canvas {
  width: 896px !important; /* 차트의 고정 너비 */
  height: 100% !important;
}

@media (min-width: 1025px) {
  .chart-container {
    overflow-x: hidden; /* 화면 너비가 1024px 초과일 때 스크롤 숨김 */
  }

  .chart-wrapper {
    min-width: 100%; /* 화면 너비가 1024px 초과일 때 최소 너비 조정 */
  }

  canvas {
    width: 100% !important; /* 화면 너비가 1024px 이상일 때 차트 너비 조정 */
  }
}
