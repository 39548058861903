@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap"); /* Noto Sans font 가져옴 */

* {
  box-sizing: border-box; /* 모든 요소에 적용 */
}

.custom-calendar {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* 좌측 정렬 */
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  background-color: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0px 24px 48px -8px rgba(39, 76, 119, 0.16);
  width: 896px; /* 너비 설정 */
  height: auto; /* 자동 높이 */
  position: relative; /* position을 relative로 설정하여 내부 요소의 위치를 쉽게 조정 */
  padding: 36px; /* 내부 여백 추가 */
}

.date-selectors {
  display: flex;
  justify-content: flex-start; /* 좌측 정렬 */
  flex-wrap: nowrap; /* 요소를 한 줄에 유지 */
  margin-bottom: 28px; /* 하단 여백 */
}

.start-date,
.end-date {
  display: flex;
  flex-direction: row; /* 가로 정렬 */
}

.selector-with-icon img {
  position: absolute;
  right: 8px;
  top: 50%; /* 상하 중심 조정 */
  transform: translateY(-50%); /* 중심 조정 */
  z-index: 2; /* z-index를 추가하여 겹침 방지 */
  height: 32px;
  pointer-events: none; /* 클릭 이벤트 방지 */
}

.selector-with-icon + .selector-with-icon {
  margin-left: 4px; /* selector-with-icon 사이 간격 */
}

.arrow-right {
  margin: 0 12px; /* 양쪽 간격 */
  width: 32px; /* 이미지 크기 조정 */
  height: 32px;
  align-self: center; /* 센터 정렬 */
}

select {
  padding: 5px 30px 5px 10px;
  font-size: 16px;
  border-radius: 4px;
  background-color: #14314a; /* 배경색 지정 */
  color: white; /* 텍스트 색상 변경 */
  cursor: pointer;
  appearance: none;
  outline: none;
  width: 100%; /* 너비를 부모 요소에 맞추기 */
  height: 100%; /* 높이를 부모 요소에 맞추기 */
}

.selector-with-icon img {
  position: absolute;
  right: 8px; /* 숫자와 이미지 사이의 간격 설정 */
  height: 20px;
  pointer-events: none;
}

.week-days {
  display: flex;
  justify-content: flex-start; /* 좌측 정렬 */
  width: 824px; /* .calendar의 너비에 맞추기 */
  height: 32px; /* 높이 설정 */
}

.week-day {
  text-align: center;
  font-weight: 500; /* 글씨 두께 */
  font-size: 12px; /* 폰트 크기 */
  line-height: 32px; /* 높이를 맞추기 위해 설정 */
  position: relative; /* 위치 조정을 위해 relative로 설정 */
}

.week-day:first-child,
.week-day:last-child {
  /* Sun, Sat */
  width: 117px;
}

.week-day:nth-child(n + 2):nth-child(-n + 6) {
  /* Mon - Fri */
  width: 118px;
}

.days-grid {
  display: grid;
  grid-template-columns: 117px repeat(5, 118px) 117px; /* 각 칸의 너비를 지정 */
}

.day {
  display: flex;
  justify-content: center;
  align-items: flex-start; /* 아이템을 상단 정렬 */
  height: 56px; /* 높이를 56px로 설정 */
  background-color: #f0f0f0; /* 배경색을 #f0f0f0로 설정 */
  cursor: pointer;
  position: relative; /* 라인을 추가하기 위해 relative로 설정 */
}

.day.sun,
.day.sat {
  width: 117px; /* Sun과 Sat의 너비를 117px로 설정 */
}

.day.mon,
.day.tue,
.day.wed,
.day.thu,
.day.fri {
  width: 118px; /* Mon - Fri의 너비를 118px로 설정 */
}

.day span {
  display: block; /* 라인 추가를 위해 블록 요소로 설정 */
  position: relative;
  z-index: 1; /* 텍스트를 라인 위에 표시 */
  margin-top: 12px; /* 숫자와 상단 사이의 간격 */
  margin-bottom: 16px; /* 숫자와 선 사이의 간격 */
}

.day::after {
  content: "";
  position: absolute;
  bottom: 8px; /* 텍스트 아래에 라인 추가 */
  left: 10%;
  width: 100px;
  height: 4px;
  background-color: #8c8c8c; /* 기본 라인 색상 */
  border-radius: 8px;
  transition: background-color 0.3s; /* 색상 전환 추가 */
}

.day.selected::after {
  background-color: #e8a44b; /* 선택된 날짜의 라인 색상 */
}

.day.empty {
  border: none;
  background-color: transparent;
  cursor: default;
}

.day.empty::after {
  display: none; /* 빈 날짜에는 라인을 표시하지 않음 */
}

@media (max-width: 1024px) {
  .custom-calendar {
    width: 708px; /* 너비 변경 */
    padding: 24px; /* 내부 여백 변경 */
  }

  .date-selectors {
    margin-bottom: 20px; /* 간격 변경 */
    flex-wrap: wrap; /* 요소를 다음 줄로 감싸줌 */
  }

  .week-days {
    width: 660px; /* 너비 변경 */
  }

  .week-day:first-child,
  .week-day:last-child {
    /* Sun, Sat */
    width: 93.71px; /* 너비 변경 */
  }

  .week-day:nth-child(n + 2):nth-child(-n + 6) {
    /* Mon - Fri */
    width: 94.51px; /* 너비 변경 */
  }

  .days-grid {
    width: 660px; /* 너비 변경 */
    grid-template-columns: 93.71px repeat(5, 94.51px) 93.71px; /* 각 칸의 너비 변경 */
  }

  .day.sun,
  .day.sat {
    width: 93.71px; /* Sun과 Sat의 너비 변경 */
  }

  .day.mon,
  .day.tue,
  .day.wed,
  .day.thu,
  .day.fri {
    width: 94.51px; /* Mon - Fri의 너비 변경 */
  }

  .day::after {
    width: 76.51px; /* 줄의 너비 변경 */
  }
}

@media (max-width: 360px) {
  .custom-calendar {
    width: 312px; /* 너비 변경 */
    height: 144px; /* 높이 변경 */
    padding: 16px 8px; /* 상하 16px, 좌우 8px */
  }

  .date-selectors {
    flex-direction: column; /* 세로 정렬 */
    align-items: center;
  }

  .start-date,
  .end-date {
    display: flex;
    flex-direction: row; /* 가로 정렬 */
    justify-content: space-between;
    width: 100%;
    margin-bottom: 8px; /* 하단 간격 추가 */
  }

  .arrow-right {
    align-self: center; /* 가운데 정렬 */
    margin: 8px 0; /* 상하 간격 추가 */
  }

  .week-days,
  .days-grid {
    display: none; /* week-days와 days-grid를 숨김 */
  }
}
