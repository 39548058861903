@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap"); /* Noto Sans font 가져옴 */

.main-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  position: relative;
  z-index: 0;
  overflow: hidden;
  background: #e7ecef;
}

.main-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 600px;
  height: 360px;
}

.main-title {
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-size: 90px; /*크기 줄임*/
  font-weight: 700;
  color: #262626;
}

.main-button {
  display: flex;
  width: 464px;
  height: 106px;
  border-radius: 32px;
  justify-content: center;
  align-items: center;
  background: #274c77;
}

.main-button-title {
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-size: 59px;
  font-weight: 600;
  text-decoration: none; /* 밑줄 제거 */
  color: #fff;
  text-align: center;
}

/* 로그인 모달 스타일 추가 */
.login-modal-container {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1000;
}

@media screen and (max-width: 360px) {
  .main-title {
    font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
    font-size: 48px; /*크기 줄임*/
    font-weight: 700;
    color: #262626;
  }

  .main-button {
    display: flex;
    width: 232px;
    height: 56px;
    border-radius: 16px;
    justify-content: center;
    align-items: center;
    background: #274c77;
  }

  .main-button-title {
    font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
    font-size: 24px;
    font-weight: 600;
    text-decoration: none; /* 밑줄 제거 */
    color: #fff;
    text-align: center;
  }
}
