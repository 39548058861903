.NM-container {
  width: 120px;
  height: 76.04px;
  position: relative;
  filter: drop-shadow(0px 4px 5px rgba(0, 0, 0, 0.14))
    drop-shadow(0px 1px 10px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2));
}

.NM-top-section {
  width: 120px;
  height: 32px;
  position: absolute;
  left: -1px;
  top: -1px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #b00020;
}

.NM-bottom-section {
  width: 120px;
  height: 32px;
  position: absolute;
  left: -1px;
  top: 31px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background: #f5f5f5;
}

.NM-id-text {
  width: 104px;
  position: absolute;
  left: 8px;
  top: 26px;
  font-size: 12px;
  font-weight: 500;
  text-align: left;
  color: #000;
}

.NM-status-text {
  width: 104px;
  position: absolute;
  top: -3px;
  left: 8px;
  font-size: 12px;
  font-weight: 600;
  text-align: left;
  color: #fff;
}
