@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap"); /* Noto Sans font 가져옴 */

.header {
  height: 96px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f8f8fb;
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;
  box-shadow: 0px 24px 48px -8px rgba(39, 76, 119, 0.16);
}

.header-title {
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-size: 35px;
  font-weight: 800;
  color: #000;
  padding-left: 80px;
}

.header-login {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-grow: 1;
  margin-right: 80px;
  gap: 16px;
}

.header-divider {
  width: 1px;
  height: 48px;
  background: #141414;
}

.header-login-icon {
  width: 48px;
  height: 48px;
}

.header-login-text {
  font-size: 30px;
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-weight: 600;
  color: #595959;
  white-space: nowrap;
  background: none;
  border: none;
  padding: 0;
  cursor: pointer;
  text-decoration: none;
}

.header-login-text:hover {
  color: #000;
}

.ModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.ModalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1000;
}

@media screen and (max-width: 360px) {
  .header-title {
    align-items: center;
    display: flex;
    padding: 0px 24px;
    font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
    font-size: 29.5px;
    font-weight: 800;
    text-align: left;
    color: #000;
    white-space: nowrap;
  }

  .header-login,
  .header-divider,
  .header-login-icon,
  .header-login-text {
    display: none;
  }
}

@media screen and (max-width: 1024px) {
  .header-title {
    align-items: center;
    display: flex;
    padding: 0px 24px;
    font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
    font-size: 35px;
    font-weight: 800;
    text-align: left;
    color: #000;
  }

  .header-login {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-self: stretch;
    flex-grow: 1;
    margin-right: 24px;
    gap: 16px;
  }
}
