@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:wght@500&display=swap"); /* Noto Sans font 가져옴 */

.pw-container {
  display: flex;
  width: 328px;
  height: 368px;
  position: relative;
  z-index: 5000;
}

.pw-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  height: 100%;
  border-radius: 12px;
  background: #fff;
}

.password-bold {
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-weight: 700;
  font-size: 23.5px;
}

.pw-input-container {
  display: flex;
  width: 280px;
  height: 48px;
  background: #fafafa;
  border-radius: 4px;
}

.pw-input_text {
  height: 100%;
  width: 100%;
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-size: 16px;
  font-weight: 500;
  color: black;
  caret-color: #e8a44b;
  margin-left: 16px;
  border: none;
  background: 0 0;
}

.pw-input-container :focus {
  outline: 1px solid #e8a44b;
}

.pw-clear-button {
  background: none;
  border: none;
  cursor: pointer;
}

.pw-cancel {
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  background: none;
}

.pw-confirm-container {
  width: 280px;
  height: 48px;
}

.pw-confirm-button {
  width: 280px;
  height: 48px;
  background: #213e57;
  border-radius: 4px;
  font-family: "Noto Sans", sans-serif; /* 폰트 서체 */
  font-size: 16px;
  font-weight: 500;
  align-items: center;
  color: #fff;
  cursor: pointer;
}
